<template>
  <div class="desktop-navbar">
    <div class="desktop-navbar__top">
      <img
        class="desktop-navbar__top-logo"
        src="@/assets/icons/main-logo.svg"
        loading="lazy"
      />

      <div class="desktop-navbar__top-menu-items">
        <div
          :class="`desktop-navbar__top-menu-items--item ${
            isActiveRoute === path ? 'active' : ''
          }`"
          v-for="{ path, name, inActiveIcon } in MENU_ITEMS"
          :key="path"
          @click="() => handleNavClick(path)"
        >
          <img
            loading="lazy"
            class="desktop-navbar__top-menu-items--item-img"
            :src="inActiveIcon"
          />

          <p
            class="desktop-navbar__top-menu-items--item-text"
            :style="{ color: isActiveRoute === path ? '#000' : '#fff' }"
          >
            {{ userSettingsState?.translations[name] || "" }}
          </p>
        </div>
      </div>
    </div>

    <div class="desktop-navbar__bottom">
      <p class="desktop-navbar__bottom-title">
        {{
          userSettingsState?.translations.currentLanguage || "Current language"
        }}
      </p>

      <div class="desktop-navbar__bottom-lang">
        <img
          loading="lazy"
          class="desktop-navbar__bottom-lang--icon"
          :src="userSettingsState?.language.icon || DEFAULT_CURRENT_LANG?.icon"
        />

        <p class="desktop-navbar__bottom-lang--text">
          {{ userSettingsState?.language.label || DEFAULT_CURRENT_LANG?.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LOCAL_STORAGE_KEYS, ROUTER_URLS } from "@/core/enums/common-enums";
import { useRoute, useRouter } from "vue-router";
import { computed, inject, onMounted, toRef } from "vue";
import { MENU_ITEMS } from "../data";
import { DEFAULT_CURRENT_LANG } from "@/core/constants/common";
import {
  UserSettingsStateSymbol,
  type UserSettingsState,
} from "@/context/ProvideUserSettings";

const router = useRouter();
const route = useRoute();

const userSettingsState = toRef(
  inject<UserSettingsState>(UserSettingsStateSymbol)
);

console.log("🚀 ~ userSettingsState:", userSettingsState.value?.translations);

const isActiveRoute = computed(() => {
  return MENU_ITEMS.find(({ path }) => path === route.path)?.path;
});

const handleNavClick = (path: ROUTER_URLS) => {
  router.push({ path });
  localStorage.setItem(LOCAL_STORAGE_KEYS.PAGE, path);
};

onMounted(async () => {
  if (route.path === "/" && !!localStorage.getItem(LOCAL_STORAGE_KEYS.LANG)) {
    const isSelectedNavItem = localStorage.getItem(LOCAL_STORAGE_KEYS.PAGE);

    router.push({ path: isSelectedNavItem || ROUTER_URLS.INFO });
  }
});
</script>

<style scoped lang="css">
@import "./DesktopNavbar.css";
</style>
