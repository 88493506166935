import { API_ENDPOINTS } from "@/core/constants/api";
import { DEFAULT_CURRENT_LANG } from "@/core/constants/common";
import type {
  IGetInfoResponse,
  IGetWifiScanResponse,
  ITransformLanguage,
} from "@/core/interfaces/store/info/request-response/request-response";
import { getTransformedLanguages } from "@/helpers/store/transform-languages";
import { API } from "@/services/axios";
import { defineComponent, provide, reactive, readonly, toRefs } from "vue";

export const UserSettingsStateSymbol = Symbol(
  "User settings provider state identifier"
);
export const UserSettingsUpdateSymbol = Symbol(
  "User settings update provider identifier"
);

type Translations = Record<string, string>;
export type TUpdate = (
  property: keyof UserSettingsState,
  value?: any,
  payload?: any
) => void | boolean;

export interface UserSettingsState {
  language: ITransformLanguage;
  translations: Translations;
  isLoading: boolean;
  languages: ITransformLanguage[];
  wifiConnected: boolean;
  wifiNetworks: IGetWifiScanResponse;
  headerState: {
    chip: string;
    device: string;
    firmware: string;
    relevance: string;
  };
  connectState: {
    connect: boolean;
    name: string;
    password: string;
  };
  codeInfo: IGetInfoResponse | null;
  connect: boolean;
}

export default defineComponent({
  name: "ProvideUserSettings",
  setup(_, { slots }) {
    const state = reactive<UserSettingsState>({
      language: DEFAULT_CURRENT_LANG,
      translations: {},
      isLoading: false,
      languages: [],
      wifiConnected: false,
      wifiNetworks: [],
      headerState: {
        chip: "",
        device: "",
        firmware: "",
        relevance: "",
      },
      connectState: {
        connect: false,
        name: "",
        password: "",
      },
      codeInfo: null,
      connect: false,
    });

    const getLanguagesData = async () => {
      try {
        const response = await API.get(API_ENDPOINTS.GET_LANG);

        const data = await response.json();

        state.languages = getTransformedLanguages(data);
      } catch (error) {
        throw error;
      }
    };

    const getTranslations = async (lang: string) => {
      try {
        const response = await API.get(API_ENDPOINTS.GET_LANG_BY_KEY(lang));

        const data = await response.json();

        state.translations = data;
      } catch (error) {
        throw error;
      }
    };

    const clearWifiConnection = async () => {
      try {
        await API.get(API_ENDPOINTS.CLEAR_WIFI);
      } catch (error) {
        throw error;
      }
    };

    const getWifiScan = async () => {
      try {
        const response = await API.get(API_ENDPOINTS.GET_SCAN_WIFI);

        const data = await response.json();

        state.wifiNetworks = data;
      } catch (error) {
        throw error;
      }
    };

    const getInfo = async () => {
      try {
        const response = await API.get(API_ENDPOINTS.GET_INFO);
        const data = await response.json();

        state.headerState.chip = data.iron.id || "";
        state.headerState.device = data.filter.id || "";
        state.headerState.firmware = data.model || "";
        state.headerState.relevance = data.code.date || "";

        state.codeInfo = data;
      } catch (error) {
        throw error;
      }
    };

    const getWifiStatus = async () => {
      try {
        const response = await API.get(API_ENDPOINTS.GET_WIFI_STATUS);
        const data = await response.json();

        if (response.status !== 200) {
          return false;
        }

        state.connectState.connect = data.connect || false;
        state.connectState.name = data.name || "";
        state.connectState.password = data.password || "";

        return true;
      } catch (error) {
        throw error;
      }
    };

    const connection = async (payload: { name: string; pass?: string }) => {
      try {
        const res = await API.get(
          API_ENDPOINTS.CONNECT_TO_WIFI(
            payload.name ? btoa(payload.name) : "",
            payload.pass ? btoa(payload.pass) : ""
          )
        );

        const data = await res.json();

        if (res.status !== 200) {
          return false;
        }

        return true;
      } catch (error) {
        return false;
      }
    };

    const update = async <K extends keyof UserSettingsState>(
      property: K,
      value?: UserSettingsState[K],
      payload?: any
    ) => {
      if (property === "connect") {
        return await connection(payload);
      }

      if (property === "languages") {
        await getLanguagesData();
      }

      if (property === "translations" && payload) {
        await getTranslations(payload);
      }

      if (property === "wifiConnected" && !payload) {
        await clearWifiConnection();
      }

      if (property === "wifiNetworks") {
        await getWifiScan();
      }

      if (property === "headerState") {
        await getInfo();
      }

      if (property === "connectState") {
        return await getWifiStatus();
      }

      if (property === "isLoading") {
        state.isLoading = payload;
      }

      if (value) {
        state[property] = value;
      }
    };

    provide(UserSettingsStateSymbol, toRefs(readonly(state)));
    provide(UserSettingsUpdateSymbol, update);

    return () => slots.default?.();
  },
});
