export enum ROUTE_NAMES {
  HOME = "/",
  INFO = "info",
  LANGUAGE = "language",
}

export enum ROUTER_URLS {
  HOME = "/",
  INFO = "/info",
  LANGUAGE = "/language",
}

export enum LOCAL_STORAGE_KEYS {
  LANG = "lang",
  TAB = "tab",
  PAGE = "page",
  IS_AUTH = "is_auth",
}

export enum INFO_TABS {
  SETUP = "SETUP",
  CODE = "CODE",
}
