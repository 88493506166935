const customFetch = async (url: string, options: RequestInit = {}) => {
  const baseUrl = import.meta.env.VITE_API_BASE_URL;
  const fullUrl = baseUrl + url;

  try {
    const response = await fetch(fullUrl, {
      ...options,
      credentials: "same-origin",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response;
  } catch (error) {
    return Promise.reject(error);
  } finally {
  }
};

const instance = {
  get: (url: string, options: RequestInit = {}) => {
    return customFetch(url, {
      method: "GET",
      ...options,
    });
  },
  post: (url: string, body: any, options: RequestInit = {}) => {
    return customFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
      ...options,
    });
  },
  put: (url: string, body: any, options: RequestInit = {}) => {
    return customFetch(url, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
      ...options,
    });
  },
  delete: (url: string, options: RequestInit = {}) => {
    return customFetch(url, {
      method: "DELETE",
      ...options,
    });
  },
};

export const API = instance;
