import type { IGetLangResponse } from "@/core/interfaces/store/info/request-response/request-response";
import { transformHtmlIconToString } from "../transform-html-icon-to-string";

export const getTransformedLanguages = (languages: IGetLangResponse) => {
  if (!languages || (languages && !Object.values(languages).length)) return [];

  return Object.values(languages).map(({ code, name, flag }) => ({
    label: name.def,
    value: code,
    icon: transformHtmlIconToString(flag),
  }));
};
