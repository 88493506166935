<script setup lang="ts">
import { ref } from "vue";
import { watch } from "vue";
import { useRoute } from "vue-router";
import MainLayout from "@/layouts/MainLayout/MainLayout.vue";
import { LOCAL_STORAGE_KEYS } from "./core/enums/common-enums";
import LanguageSelectorLayout from "@/layouts/LanguageSelectorLayout/LanguageSelectorLayout.vue";
import ProvideUserSettings from "./context/ProvideUserSettings";

const route = useRoute();
const isAuth = ref<boolean>(false);

watch(
  () => route.path,
  () => {
    isAuth.value = !!localStorage.getItem(LOCAL_STORAGE_KEYS.LANG);
  }
);
</script>

<template>
  <ProvideUserSettings>
    <MainLayout v-if="isAuth">
      <RouterView />
    </MainLayout>

    <LanguageSelectorLayout v-else>
      <RouterView />
    </LanguageSelectorLayout>
  </ProvideUserSettings>
</template>
