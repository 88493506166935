import InfoInActive from "@/assets/icons/nav/inactive/info-inactive.svg";
import LangInActive from "@/assets/icons/nav/inactive/lang-inactive.svg";

import { ROUTE_NAMES, ROUTER_URLS } from "@/core/enums/common-enums";

export interface IMenuItem {
  name: ROUTE_NAMES;
  path: ROUTER_URLS;
  inActiveIcon: string;
}

export const MENU_ITEMS: IMenuItem[] = [
  {
    name: ROUTE_NAMES.INFO,
    path: ROUTER_URLS.INFO,
    inActiveIcon: InfoInActive,
  },
  {
    name: ROUTE_NAMES.LANGUAGE,
    path: ROUTER_URLS.LANGUAGE,
    inActiveIcon: LangInActive,
  },
];
