import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
} from "vue-router";
import routes from "./routes";
import { LOCAL_STORAGE_KEYS, ROUTE_NAMES } from "@/core/enums/common-enums";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(
  (to: RouteLocationNormalized, _from: RouteLocationNormalized) => {
    if (to.meta.withAuthCheck) {
      const isAuth = !!localStorage.getItem(LOCAL_STORAGE_KEYS.LANG);

      return isAuth || { name: ROUTE_NAMES.HOME };
    }
  }
);

export default router;
