<template>
  <div class="language-layout">
    <div class="language-layout__content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="css">
.language-layout {
  width: 100%;
  height: 100vh;
  padding: 32px 40px;
  background: #3f3f3f;
}

.language-layout__content {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 16px;
  background: #e8f0ff;
  position: relative;
  z-index: 1;
}

@media (max-width: 868px) {
  .language-layout {
    padding: 8px 16px;
  }
}
</style>
