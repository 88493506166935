import { ROUTER_URLS, ROUTE_NAMES } from "@/core/enums/common-enums";

export default [
  {
    path: ROUTER_URLS.HOME,
    name: ROUTE_NAMES.HOME,
    component: () => import("../views/Home/Home.vue"),
    meta: { withAuthCheck: false },
  },
  {
    path: ROUTER_URLS.INFO,
    name: ROUTE_NAMES.INFO,
    component: () => import("../views/Info/Info.vue"),
    meta: { withAuthCheck: true },
  },
  {
    path: ROUTER_URLS.LANGUAGE,
    name: ROUTE_NAMES.LANGUAGE,
    component: () => import("../views/Language/Language.vue"),
    meta: { withAuthCheck: true },
  },

  { path: "/", redirect: ROUTE_NAMES.INFO },
];
