<template>
  <div class="mobile-navbar">
    <div class="mobile-navbar__top">
      <img src="@/assets/icons/main-logo.svg" loading="lazy" />

      <div class="mobile-navbar__top-lang">
        <p class="mobile-navbar__top-lang--title">
          {{
            userSettingsState?.translations.currentLanguage ||
            "Current language"
          }}
        </p>

        <div class="mobile-navbar__top-lang--selector">
          <img
            loading="lazy"
            class="mobile-navbar__top-lang--selector-icon"
            :src="
              userSettingsState?.language.icon || DEFAULT_CURRENT_LANG?.icon
            "
          />
        </div>
      </div>
    </div>

    <div class="mobile-navbar__menu-items">
      <div
        :class="`mobile-navbar__menu-items--item ${
          isActiveRoute === path ? 'active' : ''
        }`"
        v-for="{ path, name, inActiveIcon } in MENU_ITEMS"
        :key="path"
        @click="() => handleNavClick(path)"
      >
        <img
          loading="lazy"
          class="mobile-navbar__menu-items--item-img"
          :src="inActiveIcon"
        />
        <p
          class="mobile-navbar__menu-items--item-text"
          :style="{ color: isActiveRoute === path ? '#16191D' : '#fff' }"
        >
          {{ name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { computed, inject, onMounted, toRef } from "vue";
import { MENU_ITEMS } from "../data";
import { LOCAL_STORAGE_KEYS, ROUTER_URLS } from "@/core/enums/common-enums";
import {
  UserSettingsStateSymbol,
  type UserSettingsState,
} from "@/context/ProvideUserSettings";
import { DEFAULT_CURRENT_LANG } from "@/core/constants/common";

const router = useRouter();
const route = useRoute();

const userSettingsState = toRef(
  inject<UserSettingsState>(UserSettingsStateSymbol)
);

const isActiveRoute = computed(() => {
  return MENU_ITEMS.find(({ path }) => path === route.path)?.path;
});

const handleNavClick = (path: ROUTER_URLS) => {
  router.push({ path });
  localStorage.setItem(LOCAL_STORAGE_KEYS.PAGE, path);
};

onMounted(async () => {
  if (route.path === "/" && !!localStorage.getItem(LOCAL_STORAGE_KEYS.LANG)) {
    const isSelectedNavItem = localStorage.getItem(LOCAL_STORAGE_KEYS.PAGE);

    router.push({ path: isSelectedNavItem || ROUTER_URLS.INFO });
  }
});
</script>

<style scoped lang="css">
@import "./MobileNavbar.css";
</style>
