export const API_ENDPOINTS = {
  GET_LANG: "/api/lang",

  GET_LANG_BY_KEY: (lang: string) => `/api/lang/${lang}`,

  GET_WIFI_STATUS: "/api/wifi",

  GET_SCAN_WIFI: "/api/wifi/scan",

  CONNECT_TO_WIFI: (name: string, code: string) =>
    `/api/wifi/connect/${name}/${code ? `${code}` : "00000000"}`,

  CLEAR_WIFI: "/api/wifi/clear",

  GET_INFO: "/api/info",

  GET_CONNECTED_INFO: "/api/connected",
};
